<template>
  <div>
    <flickity id="nav-menu" :options="flickityOptions2" class="bg-white px-3">
      <b-link
        :to="{ name: 'AllProd', params: { param: 'All' } }"
        class="nav-link m-sm-2 m-0 text-primary"
      >
        {{ $t("AlProd") }}</b-link
      >

      <b-link
        :to="{ name: 'newest', params: { param: 'Products' } }"
        class="nav-link m-sm-2 m-0 text-primary"
      >
        {{ $t("Latest Products") }}</b-link
      >
      <b-link
        :to="{ name: 'SpecialOrders', params: { param: 'Product' } }"
        class="nav-link m-sm-2 m-0 text-primary"
      >
        {{ $t("SpecialOrders") }}</b-link
      >
      <b-link
        :to="{ name: 'ProdByTag', params: { ProdByTag: 1031 } }"
        class="nav-link m-sm-2 m-0 text-primary"
      >
        {{ $t("PopularProducts") }}</b-link
      >
      <b-link
        :to="{ name: 'Contact', params: { param: 'Social' } }"
        class="nav-link m-sm-2 m-0 text-primary"
      >
        {{ $t("Contact Us") }}</b-link
      >

      <a
        class="nav-link m-sm-2 m-0 text-primary"
        target="_blank"
        href="tel:+971565628959"
        >{{ $t("callUsOnPhone") }}</a
      >
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import "flickity-as-nav-for";
export default {
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions2: {
        rightToLeft: true,
        freeScroll: false,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
      },
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    var navmenu = document.getElementById("nav-menu");
    navmenu.classList.add("shadowtwo");
  },
  methods: {
    handleScroll() {
      if (this.$route.name != "Login" && this.$route.name != "Register") {
        var doc = document.documentElement;
        var navmenu = document.getElementById("nav-menu");
        if (doc.scrollTop > 10) {
          navmenu.classList.add("shadowone");
          navmenu.classList.remove("shadowtwo");
        } else {
          navmenu.classList.remove("shadowone");
          navmenu.classList.add("shadowtwo");
        }
      }
    },
  },
};
</script>

<style scoped>
.nav-link {
  margin: 10px 20px;
  font-weight: 500;

  width: max-content;
}
.router-link-active {
  border-bottom: 2px solid #92278f;
}
.shadowone {
  box-shadow: 0 2px 7px #92278f !important;
}
.shadowtwo {
  box-shadow: 0 1px 7px #f86313 !important;
}
</style>
