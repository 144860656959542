<template>
  <nav
    class="
      navbar navbar-expand-lg
      flex-nowrap
      navbar-light
      bg-light
      p-0
      justify-content-start
    "
    style="border-bottom: 1px #d1d1d1d1 solid; z-index: 988 !important"
  >
    <router-link
      :to="{ name: 'Home' }"
      :class="[
        $mq === 'sm'
          ? 'col-md-2  col-sm-2 col-5   px-1'
          : 'navbar-brand col-sm-3 col-3 px-0 mx-0',
      ]"
      aria-label="Yamhad"
    >
      <img src="@/assets/icon.png" class="w-100" width="280" alt="logo" />
    </router-link>
    <div
      class="headerDrp col-sm-3"
      v-if="
        $mq === 'lg' &&
        Object.keys(CurOptions).length > 0 &&
        Object.keys(CountryOptions).length > 0
      "
    >
      <b-dropdown
        id="dropdown-1"
        variant="none"
        class="ShipBtn"
        no-flip
        no-caret
      >
        <template #button-content style="padding: 0 !important">
          <div class="d-flex align-items-center">
            <country-flag :country="CountrySh" size="normal" />
            <div
              class="stack"
              style="
                flex-direction: column;
                align-items: flex-end;
                margin-left: 10px;
                display: flex;
              "
            >
              <span>{{ $t("ShipTo") }}</span>
              <span style="font-weight: bold; font-size: 20px">
                {{ SelectedCountryText }}</span
              >
            </div>
            <div
              style="
                width: 0px;
                height: 0px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid;
              "
            ></div>
          </div>
        </template>
        <spinner
          v-if="
            Object.keys(CurOptions).length === 0 &&
            Object.keys(CountryOptions).length === 0
          "
        ></spinner>
        <b-dropdown-form
          v-if="
            Object.keys(CurOptions).length > 0 &&
            Object.keys(CountryOptions).length > 0
          "
          @submit.prevent="UpdateCurSubmit()"
        >
          <!-- <b-form-group :label="$t('Curr')" class="drpSel">
            <b-form-select
              v-model="CurrInput"
              class="dropSelect"
              size="sm"
              :options="CurOptions"
            ></b-form-select>
          </b-form-group> -->

          <b-form-group :label="$t('ShipTo')" class="drpSel">
            <b-form-select
              v-model="CountryInput"
              class="dropSelect"
              size="sm"
              :options="CountryOptions"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-button
              type="submit"
              block
              pill
              variant="outline-secondary updCurrSubmit "
              >حفظ</b-button
            >
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
    </div>
    <div>
      <b-modal
        v-model="show"
        id="modal-center"
        centered
        title=""
        hide-footer
        size="sm"
      >
        <template #modal-header>
          <h5 class="mx-auto text-primary">{{ $t("LOGOUT SUCCESSFUL") }}</h5>
        </template>
        <div class="mx-auto text-center mb-2 mt-2">
          <i class="fa fa-check-circle fa-5x text-primary"></i>
        </div>
      </b-modal>
    </div>

    <b-row class="d-flex px-0 mx-0 px-3 col-sm-6">
      <div
        class="
          SearchSection
          d-flex
          align-items-start
          col-sm-10
          justify-content-center
          p-0
        "
      >
        <b-form
          @submit.prevent="onSubmitSearch()"
          :class="$mq === 'sm' ? 'mb-2' : 'w-75'"
        >
          <b-input-group
            prepend-html="<i class='fas fa-search '></i>"
            class="search-gruop"
          >
            <b-form-input
              id="searchFormInput"
              autocomplete="off"
              @focus="search_focus = true"
              @blur="lostFucas"
              class="SearchInput"
              type="search"
              :placeholder="$t('Search')"
              v-model="SearchInput"
              @keyup="getSuggestion"
            ></b-form-input>
          </b-input-group>
          <b-list-group class="position-absolute shadow" v-show="search_focus">
            <b-list-group-item
              button
              @click="gotoTerm(item.id)"
              v-for="item in FilteredTermsItems"
              :key="item.id"
              >{{ item.name }}</b-list-group-item
            >
            <b-list-group-item
              button
              @click="setInputValue(item)"
              v-for="(item, index) in items"
              :key="index"
              >{{ item }}</b-list-group-item
            >
            <b-list-group-item
              v-if="
                items.length == 0 &&
                SearchInput.length > 2 &&
                FilteredTermsItems.length == 0
              "
              disabled
              >{{ $t("No Available Data") }}
            </b-list-group-item>
          </b-list-group>
        </b-form>
      </div>

      <div
        class="
          HeaderIcons
          col-sm-2
          align-items-start
          d-none d-sm-inline-flex
          justify-content-center
        "
      >
        <div v-if="isLoggedIn">
          <b-dropdown
            id="dropdown-1"
            class="px-sm-2"
            text="Dropdown Button"
            variant="none"
            no-flip
            no-caret
          >
            <template #button-content>
              <div class="d-flex align-items-center">
                <span class="d-flex flex-column">
                  <i
                    class="fal fa-user mx-1"
                    style="color: #8d8d8d; font-size: 26px"
                  ></i>

                  <!-- <span class="mx-1"> {{ User.display_name }}</span> -->
                  <!-- <span class="font-weight-bold">{{$t('MyAcc')}}</span> -->
                </span>
                <div
                  style="
                    width: 0px;
                    height: 0px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid;
                  "
                ></div>
              </div>
            </template>
            <b-dropdown-item
              :to="{ name: 'User', params: { param: 'Profile' } }"
              class="text-center"
              >{{ $t("MyAcc") }}</b-dropdown-item
            >
            <!-- <b-dropdown-item :to="{name:'Orders'}"   class="text-center"  >طلباتي</b-dropdown-item> -->
            <!-- <b-dropdown-item :to="{ name: 'Shipment' }" class="text-center">{{
              $t("Shipment")
            }}</b-dropdown-item> -->

            <b-dropdown-item @click="showConBox" class="text-center">{{
              $t("SignOut")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <router-link v-else :to="{ name: 'Login' }"
          ><i class="fal fa-user"></i
        ></router-link>
        <!-- <div class="HeaderDivider"></div> -->
        <!-- <b-button variant="none" class="p-0" @click="toggleCart()">
              <i class="fal fa-shopping-cart"></i>
              <span class="CartCount" style="bottom:unset;right:unset;">
                {{ this.Cart.length }}
              </span>
                
            </b-button> -->
        <!-- <div class="HeaderDivider"></div>
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          variant="none"
          no-flip
          no-caret
        >
          <template #button-content>
            <i class="fal fa-globe" style="color: #8d8d8d; font-size: 26px"></i>
          </template>
          <b-dropdown-item-button
            variant="link"
            name="langBtn"
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            @click="UpdateLocale(lang)"
          >
            <div class="d-flex align-items-center">
              <country-flag :country="lang.flag" size="normal" />
              {{ lang.name }}
            </div>
          </b-dropdown-item-button>
        </b-dropdown> -->
        <div v-if="isLoggedIn" class="HeaderDivider"></div>
        <div v-if="isLoggedIn" class="HeaderDivider"></div>

        <div class="al_footer-itemal_footer-item-cart" v-if="isLoggedIn">
          <router-link :to="{ name: 'Favorite', params: { param: 'Products' } }"
            ><i class="fal fa-heart"></i
          ></router-link>
        </div>
      </div>
    </b-row>

    <div class="SearchResult" v-if="SearchRes">
      <div v-if="innerSpinner" class="innerSpinner">
        <img src="spinner.png" class="innerSpinner0" />
      </div>
      <div class="media" v-for="Prod in SearchResArr" v-bind:key="Prod.id">
        <div class="media-body">
          <a :href="Prod.permalink" target="_blank">
            <h5 class="mt-0 SearchResTitle">{{ Prod.name }}</h5>
          </a>
        </div>
      </div>
      <div v-if="NotFoundErr" class="NotFoundSearch text-center">
        <span>عذرا المنتج غير موجود حاليا</span>
      </div>
      <!-- <div class="ShowMoreResBtn">
                  <button >عرض المزيد</button>
                </div> -->
    </div>
  </nav>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { mapGetters } from "vuex";
import _ from "lodash";
import axios from "axios";
import i18n from "../../main.js";
import spinner from "../widgets/spinner.vue";

export default {
  components: {
    CountryFlag,
    spinner,
  },
  data() {
    var CountryVal = this.$cookies.get("shipCountry");
    var CurrVal = this.$cookies.get("wmc_current_currency");
    if (CountryVal && CurrVal) {
      var CurrVal0 = CurrVal;
      var CountryVal0 = CountryVal;

      var CountryValText;
    } else {
      CurrVal0 = "SAR";
      CountryVal0 = "SA";
      CountryValText = "السعودية";
    }

    return {
      items: "",
      FilteredTermsItems: "",
      SearchInput: "",
      search_focus: false,
      SearchRes: false,
      innerSpinner: true,
      search: true,
      NotFoundErr: false,
      SearchResArr: [],
      elementVisible: false,
      CountryInput: CountryVal0,
      CountryVal0: CountryVal0,
      CurrInput: CurrVal0,
      SelectedCountryText: CountryValText,
      CountrySh: "",
      CountryOptions: {},
      CurOptions: {},
      langs: [
        { id: 1, key: "ar", name: "العربية", flag: "ae" },
        { id: 2, key: "en", name: "English", flag: "us" },
      ],
      show: false,
    };
  },
  computed: {
    ...mapGetters([
      "config",
      "Token",
      "User",
      "CurrCountry",
      "Tags",
      "Categories",
    ]),
    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
    termsItems() {
      // return this.Categories.concat(this.Tags)
      return this.Categories;
    },
  },
  watch: {
    config(newValue) {
      //Set Config

      if (newValue) {
        let CountryObj = {};
        let CurrObj = {};

        newValue.Shipment.forEach((item) => {
          CountryObj[item.key] = item.name;
        });
        this.CountryOptions = CountryObj;

        newValue.Currency.forEach((item) => {
          CurrObj[item.key] = item.name;
        });
        this.CurOptions = CurrObj;
        var Countr = newValue.Shipment.filter((obj) => {
          return obj.key === this.CountryVal0;
        });

        this.CountrySh = Countr[0].subValue;
        this.SelectedCountryText = Countr[0].name;
      }
    },
    $route() {
      if (this.CurrCountry && this.config) {
        var Shipment = this.config.Shipment;
        var Currency = this.config.Currency;

        let CountryObj = {};
        let CurrObj = {};

        Shipment.forEach((item) => {
          CountryObj[item.key] = item.name;
        });
        this.CountryOptions = CountryObj;

        Currency.forEach((item) => {
          CurrObj[item.key] = item.name;
        });
        this.CurOptions = CurrObj;

        var Countr = Shipment.filter((obj) => {
          return obj.key === this.CountryVal0;
        });

        this.CountrySh = Countr[0].subValue;
        this.SelectedCountryText = Countr[0].name;
      }
    },
  },
  methods: {
    gotoTerm(id) {
      this.SearchInput = "";
      this.items = "";
      this.FilteredTermsItems="";
      this.$router.push({
        name: "ProdByCat",
        params: { ProdByCat: id },
      });
    },
    showConBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(i18n.t("You will be logged out, are you sure?"), {
          title: i18n.t("Please Confirm"),
          size: "sm",
          buttonSize: "sm",

          bodyClass: "text-center my-2",
          okVariant: "primary",
          okTitle: i18n.t("YES"),
          cancelTitle: i18n.t("NO"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout();
          }
          this.boxTwo = value;
        });
    },
    logout() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.$router.push({ name: "LogOut" });
      }, 1500);
    },
    getSuggestion() {
      this.DoSearch();
    },
    getTermsSuggestions() {
      const arr = this.termsItems;
      let item = this.SearchInput;

      this.FilteredTermsItems = arr.filter((el) => {
        return el.name.includes(item);
      });
    },
    lostFucas() {
      setTimeout(() => (this.search_focus = false), 300);
    },
    DoSearch: function () {
      var searchTerm = this.SearchInput;
      var url =
        process.env.VUE_APP_DEVBASEURL + "/Suggestion/" + searchTerm + "/" + 6;
      if (this.SearchInput.length > 2) {
        this.getTermsSuggestions();
        axios.get(url).then((resp) => {
          if (resp.data.status) {
            this.items = resp.data.items;
          }
        });
      } else {
        this.items = "";
      }
    },
    SearchRequest: _.debounce(function () {
      this.hideIcon();

      var value = this.SearchInput;
      var valLength = value.length;
      this.SearchResArr = [];
      if (value != "" && valLength > 2) {
        //hide Search Icon
        this.search = false;
        //Display Search Result List
        this.SearchRes = true;
        this.innerSpinner = true;
        this.NotFoundErr = false;

        //Do Request
        //  "https://alyaman.com/wp-json/wc/v3/products/?search="+value
        axios
          .get(
            "https://alyaman.com/wp-json/wc/v3/products/?search=" +
              value +
              "&per_page=4"
          )
          .then((response) => {
            if (response.status == 200) {
              //hide Inner Spinner
              this.innerSpinner = false;
              //fetch Data in Search Result List
              this.SearchResArr = response.data;
              if (this.SearchResArr.length === 0) {
                this.NotFoundErr = true;
              } else {
                this.NotFoundErr = false;
              }
            }
          });
        return "done";
      } else {
        this.SearchRes = false;
      }
    }, 1000),
    toggleCart: function () {
      this.$root.$emit("DisplayCart", true);
    },
    hideIcon() {
      this.search = false;
    },
    UpdateCurSubmit() {
      //Save New Cookie With values

      this.$cookies.set("shipCountry", this.CountryInput);
      this.$cookies.set("wmc_current_currency", this.CurrInput);
      this.$cookies.set("wmc_current_currency_old", this.CurrInput);

      // Refresh The Page
      window.location.reload();
    },
    UpdateLocale(lang) {
      //Change Direction
      if (lang.key === "ar") {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
      }
      //Change Locale
      this.$root.$i18n.locale = lang.key;
    },
    setInputValue(name) {
      this.SearchInput = name;
      this.onSubmitSearch();
    },
    onSubmitSearch: function () {
      if (this.SearchInput.length > 2) {
        let searchFormInput = document.getElementById("searchFormInput");
        searchFormInput.blur();

        let searchValue = this.SearchInput;
        this.SearchInput = "";
        this.items = "";
        this.$router.push({
          name: "Search",
          params: { term: searchValue },
        });
      }
    },
  },
  mounted() {
    var Shipment = this.config.Shipment;
    var Currency = this.config.Currency;

    if (Shipment && Currency && Shipment.length > 0 && Currency.length > 0) {
      let CountryObj = {};
      let CurrObj = {};

      Shipment.forEach((item) => {
        CountryObj[item.key] = item.name;
      });
      this.CountryOptions = CountryObj;

      Currency.forEach((item) => {
        CurrObj[item.key] = item.name;
      });
      this.CurOptions = CurrObj;

      var Countr = Shipment.filter((obj) => {
        return obj.key === this.CountryVal0;
      });

      this.CountrySh = Countr[0].subValue;
      this.SelectedCountryText = Countr[0].name;
    }
  },
};
</script>

<style scoped>
.search-gruop {
  border: rgb(185, 168, 168) solid 1px;
  border-radius: 15px;
  padding: 0px 11px;
}
.list-group {
  width: 200px !important;
}
.list-group-item:hover {
  color: #fff !important;
  background-color: #eb5b2c !important;
}
.SearchSection {
  position: relative;
  top: 3px;
}
.navbar {
  padding: 10px 5px 8px 5px;
}
.bg-light {
  background-color: #fff !important;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 0px;
}
.SearchInput {
  border: none !important;
  margin-bottom: 0px !important;
}
.input-group-text {
  font-weight: 100 !important;
  border: none !important;
  background: none !important;
  padding: 0px 5px;
  font-size: 1.1em;
}
.SearchInput:focus {
  border: none !important;
  box-shadow: none;
}
.SearchResImg {
  max-width: 40%;
  height: auto;
}

.SearchResImg img {
  width: 90%;
  height: auto;
}
.SearchInput:focus + .SearchIcon {
  display: none;
}
.SearchIcon {
  position: relative;
  right: 25px;
  top: 32px;
}
.SearchResTitle {
  font-size: 10px;
  color: #000;
  padding-bottom: 6px;
  border-bottom: 1px #f6601a solid;
}

.HeaderIcons a,
.HeaderIcons button {
  color: #8d8d8d;
  font-size: 26px;
  margin: 0 12px;
}
.HeaderIcons a:hover {
  color: #f6601a;
  /* color: #fe6a00; */
}
.HeaderDivider {
  border-left: 1px solid #d1d3d4;
  height: 42px;
}
.SearchResult {
  width: 32%;
  position: absolute;
  right: 32%;
  top: 85%;
  background: white;
  padding: 16px;
  z-index: 990;
  margin: 20px 0;
  border: 1px rgb(238, 238, 238) solid;
  border-radius: 20px;
  box-shadow: 0px 0px 30px 8px rgb(0 0 0 / 36%);
  transition: all 0.4s ease 0s;
}

.SearchResult .media .media-body {
  margin: 0 16px;
}

.ShowMoreResBtn {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.ShowMoreResBtn button {
  border-radius: 32px;
  padding: 8px;
  background: white;
  border: 1px #fa660d solid;
  color: #fa660d;
}

.CartCountHeading {
  background-color: #92278f;
  padding: 4px;
  position: relative;
  bottom: 16px;
  left: 22px;
  font-size: 11px;
  opacity: 0.9;
  line-height: 17px;
  letter-spacing: -0.5px;
  height: 17px;
  min-width: 17px;
  border-radius: 99px;
  color: #fff;
  text-align: center;
}
.innerSpinner0 {
  width: 20%;
  text-align: center;
  position: relative;
  padding: 16px;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 40%;
  z-index: 999;
  -webkit-animation: spin-data-v-c4c7653a 4s linear infinite;
  animation: spin-data-v-c4c7653a 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 720px) {
  .HeaderIcons {
    display: none !important;
  }
}
.list-group {
  width: 75% !important;
  text-align: center;
}
@media only screen and (max-width: 450px) {
  .list-group {
    width: 100% !important;
  }
}
</style>