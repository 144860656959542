<template>
  <div class="headerTop" v-if="$mq === 'sm'">
    <div class="headerDrp d-flex align-items-center">
      <b-dropdown
        id="dropdown-0"
        variant="none"
        class="ShipBtn col-6"
        no-flip
        no-caret
      >
        <template #button-content style="margin-bottom: 0 !important">
          <div class="d-flex align-items-center">
            <div
              class="stack"
              style="
                color: white;
                flex-direction: row;
                align-items: flex-end;
                margin-left: 10px;
                display: flex;
              "
            >
              <span style="font-size: 12px; margin: 0 6px"> الشحن إلى :</span>
              <span style="font-weight: bold; font-size: 12px">
                {{ SelectedCountryText }}</span
              >
            </div>
            <div
              style="
                color: white;
                width: 0px;
                height: 0px;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid;
              "
            ></div>
          </div>
        </template>
        <b-dropdown-form @submit.prevent="UpdateCurSubmit()">
          <!-- <b-form-group label="العملة" class="drpSel">
            <b-form-select
              v-model="CurrInput"
              size="sm"
              class="dropSelect"
              :options="CurOptions"
            ></b-form-select>
          </b-form-group> -->

          <b-form-group label="الشحن الى" class="drpSel">
            <b-form-select
              v-model="CountryInput"
              size="sm"
              class="dropSelect"
              :options="CountryOptions"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-button
              type="submit"
              block
              pill
              variant="outline-secondary updCurrSubmit"
              >حفظ</b-button
            >
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
      <b-row class="col-6" align-h="end" align-v="center">
        <div class="HeaderIcons align-items-center d-inline-flex">
          <!-- <b-dropdown
            id="dropdown-1"
            text="Dropdown Button"
            variant="none"
            class="mb-0"
            no-flip
            no-caret
          >
            <template #button-content>
              <i
                class="fal fa-globe"
                style="color: #8d8d8d; font-size: 26px"
              ></i>
            </template>
            <b-dropdown-item-button
              variant="link"
              name="langBtn"
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              @click="UpdateLocale(lang)"
            >
              <div class="d-flex align-items-center">
                <country-flag :country="lang.flag" size="normal" />
                {{ lang.name }}
              </div>
            </b-dropdown-item-button>
          </b-dropdown> -->
          <!-- <div v-if="isLoggedIn" class="HeaderDivider"></div> -->

          <div v-if="isLoggedIn" class="d-flex align-items-center">
            <div
              class="stack"
              style="
                color: white;
                flex-direction: row;
                    align-items: center;

                display: flex;
              "
            >
              <span style="font-size: 12px; margin: 0 6px" @click="showConBox">
                <i
                  class="fal fa-sign-out mx-1"
                  style="color: #8d8d8d; font-size: 26px"
                ></i>
              </span>
              <span style="font-weight: bold; font-size: 12px;  ">
                {{ $t("SignOut") }}</span
              >
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <div>
      <b-modal
        v-model="show"
        id="modal-center"
        centered
        title=""
        hide-footer
        size="sm"
      >
        <template #modal-header>
          <h5 class="mx-auto text-primary">{{ $t("LOGOUT SUCCESSFUL") }}</h5>
        </template>
        <div class="mx-auto text-center mb-2 mt-2">
          <i class="fa fa-check-circle fa-5x text-primary"></i>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
// import CountryFlag from "vue-country-flag";
import i18n from "../../main.js";
export default {
  components: {},
  computed: {
    ...mapGetters(["config", "Token", "User", "CurrCountry"]),
    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    var CountryVal = this.$cookies.get("shipCountry");
    var CurrVal = this.$cookies.get("wmc_current_currency");

    if (CountryVal && CurrVal) {
      var CurrVal0 = CurrVal;
      var CountryVal0 = CountryVal;
      var CountryValText;
      switch (CountryVal) {
        case "SA":
          CountryValText = "السعودية";
          break;
        case "OM":
          CountryValText = "سلطنة عمان";
          break;
        case "YE":
          CountryValText = "اليمن";
          break;
        case "LB":
          CountryValText = "لبنان";
          break;
        case "IQ":
          CountryValText = "العراق";
          break;
        case "AE":
          CountryValText = "الامارات";
          break;
        case "PS":
          CountryValText = "فلسطين";
          break;
        default:
          CountryValText = "السعودية";
      }
    } else {
      CurrVal0 = "SAR";
      CountryVal0 = "SA";
      CountryValText = "السعودية";
    }

    return {
      langs: [
        { id: 1, key: "ar", name: "العربية", flag: "ae" },
        { id: 2, key: "en", name: "English", flag: "us" },
      ],
      show: false,
      SearchInput: "",
      SearchRes: false,
      innerSpinner: true,
      search: true,
      NotFoundErr: false,
      SearchResArr: [],
      elementVisible: false,
      CountryInput: CountryVal0,
      CountryVal0: CountryVal0,
      CurrInput: CurrVal0,
      SelectedCountryText: CountryValText,
      CountryOptions: {},
      CurOptions: {
        CNY: "اليوان الصيني",
        SAR: "الريال السعودي",
        AED: "الدرهم الاماراتي",
        USD: "الدولار الاميركي",
      },
      boxTwo: "",
    };
  },
  watch: {
    config(newValue) {
      //Set Config

      if (newValue) {
        let CountryObj = {};

        newValue.Shipment.forEach((item) => {
          CountryObj[item.key] = item.name;
        });
        this.CountryOptions = CountryObj;

        var Countr = newValue.Shipment.filter((obj) => {
          return obj.key === this.CountryVal0;
        });

        this.CountrySh = Countr[0].subValue;
        this.SelectedCountryText = Countr[0].name;
      }
    },
  },
  methods: {
    showConBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(i18n.t("You will be logged out, are you sure?"), {
          title: i18n.t("Please Confirm"),
          size: "sm",
          buttonSize: "sm",

          bodyClass: "text-center my-2",
          okVariant: "primary",
          okTitle: i18n.t("YES"),
          cancelTitle: i18n.t("NO"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout();
          }
          this.boxTwo = value;
        });
    },
    logout() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.$router.push({ name: "LogOut" });
      }, 1500);
    },
    hideIcon() {
      this.search = false;
    },

    UpdateLocale(lang) {
      //Change Direction
      if (lang.key === "ar") {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
      }
      //Change Locale
      this.$root.$i18n.locale = lang.key;
    },
    setInputValue(name) {
      this.SearchInput = name;
      this.onSubmitSearch();
    },
    onSubmitSearch: function() {
      if (this.SearchInput.length > 2) {
        let searchFormInput = document.getElementById("searchFormInput");
        searchFormInput.blur();
        this.$router.push({
          name: "Search",
          params: { term: this.SearchInput },
        });
      }
    },
    UpdateCurSubmit() {
      //Do Request To Get New Data ---later
      //this.getProdByTax();

      //Display Spinner

      //Hide Spinner

      //Save New Cookie With values
      this.$cookies.set("shipCountry", this.CountryInput);
      this.$cookies.set("wmc_current_currency", this.CurrInput);
      this.$cookies.set("wmc_current_currency_old", this.CurrInput);

      // Refresh The Page
      window.location.reload();
      //this.$forceUpdate();
    },
    //  SetLang(){
    //    window.location.reload()
    //  }
  },
  mounted() {
    var Shipment = this.config.Shipment;
    var Currency = this.config.Currency;

    if (Shipment && Currency && Shipment.length > 0 && Currency.length > 0) {
      let CountryObj = {};
      let CurrObj = {};

      Shipment.forEach((item) => {
        CountryObj[item.key] = item.name;
      });
      this.CountryOptions = CountryObj;

      Currency.forEach((item) => {
        CurrObj[item.key] = item.name;
      });
      this.CurOptions = CurrObj;

      var Countr = Shipment.filter((obj) => {
        return obj.key === this.CountryVal0;
      });

      this.CountrySh = Countr[0].subValue;
      this.SelectedCountryText = Countr[0].name;
    }
  },
};
</script>

<style scoped>
.headerTop div div button {
  margin: 0;
}
.headerTop {
  background: #2f2f2f;
}

.headerTopLinks a {
  color: #f0f0f0;
  font-size: 12px;
  margin: 6px;
  font-weight: 600;
}
.HeaderDivider {
  border-left: 1px solid #d1d3d4;
  height: 22px;
}
.headerTopDivider {
  background: #999999;
  width: 1px;
  margin: 8px 0;
}

#dropdown-0 {
  max-height: 36px !important;
}
.HeaderIcons a,
.HeaderIcons button {
  color: #8d8d8d;
  font-size: 26px;
  margin: 0 12px;
}
.HeaderIcons a:hover {
  color: #f6601a;
  /* color: #fe6a00; */
}
</style>
