<template>
  <div class="Footer" style="background-color: #f5f5f5ec !important">
    <div class="mobileFooter d-flex d-sm-none">
      <div class="al_footer-item">
        <router-link :to="{ name: 'Home' }" class="flex justify-center">
          <i class="far fa-home"></i>
          <span>{{ $t("MainOn") }}</span>
        </router-link>
      </div>
      <div class="al_footer-item">
        <router-link
          :to="{ name: 'Rate', params: { param: 'Us' } }"
          class="flex justify-center"
        >
          <i class="far fa-thumbs-up"></i>
          <span>{{ $t("RateUs") }}</span>
        </router-link>
      </div>
      <div class="al_footer-item al_footer-item-cart" v-if="isLoggedIn">
        <router-link
          :to="{ name: 'Favorite', params: { param: 'All' } }"
          class="flex justify-center"
        >
          <i class="fal fa-heart"></i>
          <span>{{ $t("favourite") }}</span>
        </router-link>
      </div>
      <div class="al_footer-item">
        <a
          href="https://wa.me/971565628959?text= مرحباً، أريد الاستفسار عن منتجاتكم"
          class="flex justify-center"
          @click="whatsAppEvent"
        >
          <i class="fab fa-whatsapp"></i>
          <div v-show="elementVisible" class="PopupSoc">
            {{ $t("MoreDetails") }}
          </div>
          <span>{{ $t("WhatsApp") }}</span>
        </a>
      </div>
      <div class="al_footer-item">
        <router-link
          :to="{ name: 'User', params: { param: 'Profile' } }"
          class="flex justify-center"
          v-if="isLoggedIn"
        >
          <i class="far fa-user"></i>
          <span>{{ $t("MyAcc") }}</span>
        </router-link>
        <router-link
          :to="{ name: 'Login', params: { param: 'User' } }"
          class="flex justify-center"
          v-if="!isLoggedIn"
        >
          <i class="far fa-user"></i>
          <span>{{ $t("Login") }}</span>
        </router-link>
      </div>
    </div>

    <div class="MainFooter">
      <div class="inform-footer">
        <div class="row w-100 text-center justify-content-center m-0 pt-5">
          <div class="LinkList col-sm-3 col-6">
            <h3>{{ $t("SearchIn") }}</h3>
            <router-link v-if="$route.name != 'Home'" :to="{ name: 'Home' }"
              >{{ $t("Main") }}
            </router-link>
            <a v-else href="#" @click="goToTop()">{{ $t("Main") }}</a>

            <router-link :to="{ name: 'AllCat', params: { param: 'All' } }">{{
              $t("AlCat")
            }}</router-link>
            <!-- <router-link :to="{ name: 'AllProd'  , params: { param:'All' }}">{{
                          $t("AlProd")
                        }}</router-link> -->
            <router-link
              :to="{ name: 'ProdByTag', params: { ProdByTag: 1031 } }"
              >{{ $t("Popular") }}</router-link
            >
            <router-link
              :to="{ name: 'ProdByTag', params: { ProdByTag: 1138 } }"
              >{{ $t("Offers") }}</router-link
            >
            <!-- <a href="https://alyaman.com/%d8%b1%d8%a7%d8%a6%d8%ac%d8%a9/">{{ $t('Popular') }}</a> -->
            <!-- <a href="https://alyaman.com/%d8%b9%d8%b1%d9%88%d8%b6/"> {{$t('Offers')}} </a> -->
            <!-- <a href="https://alyaman.com/%d9%82%d8%b1%d9%8a%d8%a8%d8%a7%d9%8b-%d9%81%d9%8a-%d8%a7%d9%84%d8%a3%d8%b3%d9%88%d8%a7%d9%82/">{{$t('Soon')}}</a> -->
          </div>
          <div class="LinkList col-sm-3 col-6">
            <h3>{{ $t("CustSer") }}</h3>
            <!-- <a href="https://alyaman.com/about-us-arabic/">{{$t('About')}}</a> -->
            <router-link :to="{ name: 'About', params: { param: 'Us' } }">{{
              $t("About")
            }}</router-link>

            <!-- <a href="https://alyaman.com/%d9%83%d9%8a%d9%81%d9%8a%d8%a9-%d8%aa%d9%82%d8%af%d9%8a%d9%85-%d8%b7%d9%84%d8%a8-%d8%b4%d8%b1%d8%a7%d8%a1/">{{ $t('HowToBuy') }}</a> -->
            <!-- <a href="https://www.alyaman.com/%d8%a7%d9%84%d8%b4%d8%ad%d9%86-%d9%88%d8%a7%d9%84%d8%aa%d8%b3%d9%84%d9%8a%d9%85-2/">{{$t('Ship')}}</a> -->
            <!-- <router-link :to="{name:'Orders'}" >{{$t('YourOrders')}}</router-link> -->
            <!-- <a href="https://www.alyaman.com/remember/">{{$t('Terms&Conds')}}</a> -->
            <!-- <a href="https://alyaman.com/privacy-policy/">{{$t('Privacy')}}</a> -->
            <router-link
              :to="{ name: 'User', params: { param: 'Profile' } }"
              class="flex justify-center"
              v-if="isLoggedIn"
            >
              <span>{{ $t("MyAcc") }}</span>
            </router-link>
            <router-link
              :to="{ name: 'Login', params: { param: 'User' } }"
              class="flex justify-center"
              v-else
            >
              <span>{{ $t("MyAcc") }}</span>
            </router-link>
            <router-link :to="{ name: 'terms', params: { param: 'All' } }">{{
              $t("Terms&Conds")
            }}</router-link>
            <router-link :to="{ name: 'privacy', params: { param: 'All' } }">{{
              $t("Privacy")
            }}</router-link>
          </div>
          <div class="LinkList col-sm-3 col-6">
            <h3>{{ $t("CallUS") }}</h3>
            <a target="_blank" href="tel:+971565628959">{{
              $t("callUsOnPhone")
            }}</a>

            <a href="mailto:support@yamhad.net" target="_blank"
              >support@yamhad.net</a
            >
            <a
              target="_blank"
              href="https://wa.me/971565628959?text= مرحباً، أريد الاستفسار عن منتجاتكم"
              >tel: +971 56 562 8959</a
            >
          </div>

          <div class="LinkList col-sm-3 col-6">
            <h3>{{ $t("RateUs") }}</h3>
            <router-link :to="{ name: 'AllRate', params: { param: 'All' } }">{{
              $t("CustRates")
            }}</router-link>
            <router-link :to="{ name: 'Rate', params: { param: 'Us' } }">{{
              $t("RateYamhad")
            }}</router-link>
            <a
              href="https://wa.me/971565628959?text=مرحباً،أريد أن اصبح وكيلاً للشركة"
              target="_blank"
              >كن وكيلاً لنا
            </a>
            <!-- <b-button variant="link" style="color:white" @click="UpdateLocale({key:'ar'})" >{{ $t('Ar') }}</b-button>
                                            <b-button variant="link" style="color:white" @click="UpdateLocale({key:'en'})">English</b-button> -->
          </div>
        </div>
        <!-- <div class="PayMethods" style="text-align: center; width: 100%">
                                              <img width="38" height="40" src="https://shoplineimg.com/assets/footer/card_visa.png" alt="pay using visa credit card"/>
                                              <img width="38" height="22" src="http:///www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_1.gif" border="0" alt="pay using master card credit card"/>
                                              <img width="38" height="40" src="https://shoplineimg.com/assets/footer/card_paypal.png" alt="pay using paypal" />
                                            </div> -->
        <div class="FooterHeading">
          <div class="FooterHeadingContent">
            <h4>تابعونا على</h4>
          </div>
        </div>

        <div class="mx-auto mt-3 text-center">
          <b-avatar
            size="2.5em"
            href="https://t.me/yamhad"
            style="background-color: #2ca4df"
            class="align-baseline mx-2 bg-white"
            target="_blank"
          >
            <i class="fab fa-telegram icon"></i>
          </b-avatar>
          <b-avatar
            size="2.5em"
            href="https://www.pinterest.de/Yamhad_online/"
            class="align-baseline mx-2 bg-white"
            target="_blank"
          >
            <i class="fab fa-pinterest icon"></i>
          </b-avatar>
          <b-avatar
            size="2.5em"
            href="https://wa.me/971565628959?text=مرحباً، أريد الاستفسار عن منتجاتكم"
            class="align-baseline mx-2 bg-white"
            @click="whatsAppEvent"
            target="_blank"
          >
            <i class="fab fa-whatsapp icon"></i>
          </b-avatar>
          <b-avatar
            size="2.5em"
            href="mailto:support@yamhad.net"
            class="align-baseline mx-2 bg-white"
            target="_blank"
          >
            <i class="fas fa-envelope icon"></i>
          </b-avatar>
          <b-avatar
            size="2.5em"
            href="https://www.instagram.com/yamhad__china"
            class="align-baseline mx-2 bg-white"
            target="_blank"
          >
            <i class="fab fa-instagram icon"></i>
          </b-avatar>
          <b-avatar
            size="2.5em"
            href="https://www.facebook.com/yamhadOnline"
            class="align-baseline mx-2 bg-white"
            target="_blank"
          >
            <i class="fab fa-facebook-f icon"></i>
          </b-avatar>
        </div>
        <div class="CopyRightsFooter">
          <p>{{ $t("CopyRights") }}</p>
          <!-- <div class="PayMethods" style="text-align: center; width: 100%">
                      <img width="38" height="40" src="https://shoplineimg.com/assets/footer/card_visa.png" alt="pay using visa credit card"/>
                      <img width="38" height="22" src="http:///www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_1.gif" border="0" alt="pay using master card credit card"/>
                      <img width="38" height="40" src="https://shoplineimg.com/assets/footer/card_paypal.png" alt="pay using paypal" />
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      elementVisible: false,
    };
  },
  created() {
    setTimeout(() => {
      this.elementVisible = true;
    }, 6000);
    setTimeout(() => (this.elementVisible = false), 15000);
  },
  computed: {
    ...mapGetters(["Cart", "Token", "User"]),
    isLoggedIn() {
      if (!_.isEmpty(this.User) && !_.isEmpty(this.Token)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    UpdateLocale(lang) {
      //Change Direction
      if (lang.key === "ar") {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.setAttribute("dir", "ltr");
      }
      //Change Locale
      this.$root.$i18n.locale = lang.key;
    },
    whatsAppEvent() {
      this.$gtag.event("E_WhatsAPP", { method: "Google" });
    },
    goToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
@media (max-width: 554px) {
  .inform-footer {
    padding-bottom: 25px;
  }
}

.icon {
  font-size: 25px;
  color: #2f2f2f;
}

.MainFooter {
  background-color: #2f2f2f;
}

.FooterHeadingContent h4 {
  margin-top: 10px;
  text-align: center;

  color: white !important;
}

.LinksLists {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.LinkList {
  padding: 0;
  display: flex;
  flex-direction: column;

  padding-bottom: 20px;
}

.LinkList h3 {
  color: white !important;
  font-size: 28px;
}

.LinkList a {
  color: #c9c9c9;
  font-size: 12px;
  padding: 2px 0;
  transition: 0.5s ease-out;
}

.FooterHeading {
  display: flex;
  color: white;
  font-weight: bold;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.SocialLinks {
  display: flex;
  text-align: center;
  font-weight: 100;
  font-size: 30px;
}

.SocialLinkI {
  margin: 8px;
}

.SocialLinkI span {
  line-height: 19px;
  width: 60px;
  text-align: center;
  border: 1px #2f2f2f solid;
  padding: 16px 0;
  border-radius: 50%;
  color: white;
  box-shadow: 0 0 2px 0px;
  transition: 1s ease-out;
}

.CopyRightsFooter {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  color: white;
  border-top: 1px #6e6e6e solid;
  margin: 0 110px;

  padding: 16px 0;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

.PayMethods img {
  margin: 6px;
}

.mobileFooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 65px;
  background-color: #fff;
  z-index: 990;
  box-shadow: 0 1px 3px 0;
  box-sizing: border-box;
}

.al_footer-item {
  flex: 1;
  padding: 0px;
  width: min-content;
  float: right;
}

.al_footer-item i {
  color: #fe6a00;
  font-size: 30px !important;
}

.al_footer-item i:active {
  color: black;
}

.al_footer-item a {
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
  color: #3a3e4a;
}

.al_footer-item span {
  white-space: nowrap;
  font-size: 14px;
}

.PopupSoc {
  position: absolute;
  bottom: 63px;
  white-space: nowrap;
  background: white;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.PopupSoc::after {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  bottom: -8px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: rotate(180deg);
}

.SocialLinkI a:active span.SocialLinkSpan {
  box-shadow: 0 0 6px 3px;
}

.LinkList a:active {
  color: white;
  text-decoration: underline !important;
}
</style>
