import axios from "axios";
import cookie from 'vue-cookies'


const state = {
    Categories: [],
    Tags: [],
    AllCatLayout: []
}

const getters = {
    Categories: state => state.Categories,
    Tags: state => state.Tags,
    AllCatLayout: state => state.AllCatLayout
}

const actions = {



    //Get Terms
    getTerms({ commit }, obj) {
        var CountryVal = cookie.get('shipCountry');
        var CurrVal = cookie.get('wmc_current_currency');
        let apiCountry;
        let cur;
        let country
        if (CountryVal && CurrVal) {

            cur = CurrVal;
            country = CountryVal;
        } else {

            axios.get(process.env.VUE_APP_DEVBASEURL + '/AutoCurrancy').then(function(response) {
                apiCountry = response.data.countryCode;

                if (apiCountry == 'EG') {
                    cur = 'EGY';
                    country = apiCountry;
                } else if (apiCountry == 'AE') {
                    cur = 'AED';
                    country = apiCountry;
                } else {
                    cur = 'SAR';
                    country = 'SA';
                }
                cookie.set('shipCountry', country);
                cookie.set('wmc_current_currency', cur);

            });
        }
        const SetUrl = process.env.VUE_APP_DEVBASEURL + '/Terms/' + cur + '/' + country + '/' + obj.breakpoint;
        axios.get(SetUrl).then(function(response) {

            if (response.data.status) {

                var data = response.data.items;
                commit('Categories', data.Categories)
                commit('Tags', data.Tags)
                commit('AllCatLayout', data.layout)
            }


        })
    }

}

const mutations = {
    Categories: (state, Categories) => (state.Categories = Categories),
    Tags: (state, Tags) => (state.Tags = Tags),
    AllCatLayout: (state, AllCatLayout) => (state.AllCatLayout = AllCatLayout)
}

export default {
    state,
    getters,
    actions,
    mutations

}