import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import https from 'https';
import VueCookie from 'vue-cookies';
import Vuelidate from 'vuelidate'
import VueMq from 'vue-mq'
import VueCryptojs from 'vue-cryptojs'
import VueI18n from 'vue-i18n';
import lang from '../lang/index';
import axios from 'axios'
import LoadScript from 'vue-plugin-load-script';
import { BootstrapVue, IconsPlugin, ProgressPlugin } from 'bootstrap-vue'
import { ClientTable, } from 'vue-tables-2';
import VueProgressBar from 'vue-progressbar'
import cookie from 'vue-cookies'
import './assets/custom.scss';
import VueImg from 'v-img';
import VueLazyload from 'vue-lazyload'
import VueGtag from "vue-gtag";
import VueTidio from 'vue-tidio';
import JwPagination from 'jw-vue-pagination';
import VueMeta from 'vue-meta'

const vueImgConfig = {
  altAsTitle: false,
  sourceButton: false,
  openOn: "click",
  thumbnails: false,
};
    //  VueLazyload options
const loadimage = require('./assets/loader.gif')
const errorimage = require('./assets/placeholder.png')


Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
})
Vue.use(VueImg, vueImgConfig);

Vue.use(ProgressPlugin)
Vue.component('jw-pagination', JwPagination);
Vue.use(VueTidio, { appKey: 'rxxltfnin2b2ipmulxr0ulpkfulbvdrt' });
Vue.use(VueGtag, {
    config: { id: "G-9721VB7X69" }
});
Vue.use(VueCryptojs)
Vue.use(VueCookie)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ClientTable, {}, false, 'bootstrap4', {});
Vue.use(LoadScript);
Vue.use(VueMeta)
Vue.use(VueMq, {
    breakpoints: {
        sm: 450,
        md: 1250,
        lg: Infinity,
    }
})
Vue.use(VueProgressBar, {
    color: '#f6601a',
    failedColor: 'red',
    height: '18px'
})


Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'ar',
    fallbackLocale: 'en',
    messages: lang
});

axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
Vue.config.productionTip = false

export default i18n;


const prom = Promise.resolve(function() {
        var CountryVal = cookie.get('shipCountry');
        var CurrVal = cookie.get('wmc_current_currency');
        let cur;
        let country
        if (CountryVal && CurrVal) {

            cur = CurrVal;
            country = CountryVal;
        } else {
            axios.get(process.env.VUE_APP_DEVBASEURL + '/AutoCurrancy').then(function(response) {
                let apiCountry = response.data.countryCode;

                if (apiCountry == 'EG') {
                    cur = 'EGY';
                    country = apiCountry;
                } else if (apiCountry == 'AE') {
                    cur = 'AED';
                    country = apiCountry;
                } else {
                    cur = 'SAR';
                    country = 'SA';
                }
                cookie.set('shipCountry', country);
                cookie.set('wmc_current_currency', cur);


            })
        }


    }


);

prom.then(() => {



    new Vue({
        router,
        store,
        VueCookie,
        i18n,
        render: h => h(App),
    }).$mount('#app')

});


// set event to tidio bottun
var intervalButton = setInterval(() => {
    var iframe = document.getElementById("tidio-chat-iframe");

    if (iframe) {

        var buttonBody = iframe.contentWindow.document.getElementsByTagName("div")[3];
        if (buttonBody) {
            clearInterval(intervalButton);
            buttonBody.addEventListener("click", function() {
                if (buttonBody.classList.contains('chat-closed'))
                    VueGtag.event('E_Chat', { method: 'Google' })

            });
        }

    }
}, 5000);