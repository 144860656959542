import Vue from 'vue'
import axios from "axios";
import VueCookie from 'vue-cookies';
import CryptoJS from 'crypto-js'
import i18n from '../../main.js'
import store from '../../store'
import router from '../../router'

const state = {
    User: {},
    Token: {},
}

const getters = {
    User: state => state.User,
    Token: state => state.Token,

}

const actions = {

    RegisterUser({ commit }, form) {

        const vm = new Vue({})
        var url = process.env.VUE_APP_DEVBASEURL + '/RegisterByMail';
        axios.post(url, form).then(function(response) {

            if (response.data.status) {
                commit('Token', "testToken")
                let data = {
                    userMail: form.MailI,
                    password: form.PassI
                }
                store.dispatch('LoginWithMail', data).then(function() {
                    router.push({ name: "Home" })
                })

            }

        }).catch((error) => {
            vm.$bvToast.toast(error.response.data.message, {
                title: i18n.t('Alert'),
                variant: 'danger',
                solid: true,
                toaster: "b-toaster-top-center",
                noCloseButton: true,
                toastClass: "text-center"
            })
        })


    },
    LoginWithMail({ commit }, form) {

        const vm = new Vue({})
        var url = process.env.VUE_APP_DEVBASEURL + '/LoginByMail';
        return new Promise((resolve, reject) => {
            axios.post(url, form).then(function(resp) {

                if (resp.data.status) {
                    //Encrypt Token 
                    const key = process.env.VUE_APP_ENCKEY
                    const txt = resp.data.items.token;
                    const cipher = CryptoJS.AES.encrypt(JSON.stringify(txt), key).toString()
                    commit('User', resp.data.items.user)
                    commit('Token', txt)
                        //Save Token in Cookies
                    VueCookie.set('token', cipher)
                        //Set Token Auth header Axios
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + txt;
                    resolve(resp.data.status);



                }
            }).catch((error) => {
                reject(error);


                let errorMessage = i18n.t('Unable To Login Worng Mail Or Password')
                vm.$bvToast.toast(errorMessage, {
                    title: i18n.t('Alert'),
                    variant: 'danger',
                    solid: true,
                    toaster: "b-toaster-top-center",
                    noCloseButton: true,
                    toastClass: "text-center"

                })
            })
        })

    },
    LoginWithSocialite({ commit }, data) {

        //Encrypt Data 
        const key = process.env.VUE_APP_ENCKEY //
            //const iv = process.env.VUE_APP_ENCIV // 
        const txt = data.token;

        const cipher = CryptoJS.AES.encrypt(JSON.stringify(txt), key).toString()
        var user = JSON.parse(data.user.replace(/&quot;/g, '"'));

        //Save On Cookie

        commit('User', user)
        commit('Token', txt)
        VueCookie.set('token', cipher)

    },
    LoginWithCookie({ commit }, data) {

        //get user inf
        var url = process.env.VUE_APP_DEVBASEURL + '/GetUser';

        //Dcrypyt Token
        var key = process.env.VUE_APP_ENCKEY;


        //Dcrypt
        const dcrypted = CryptoJS.AES.decrypt(data.Token, key).toString(CryptoJS.enc.Utf8);
        var replacedToken = dcrypted.replace(/['"]+/g, '');

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + replacedToken;

        axios.get(url).then(resp => {

            if (resp.data.status) {
                commit('User', resp.data.items.user)
                commit('Token', replacedToken)
            }
        })

    },

    UpdateUser({ commit }, form) {



        var url = process.env.VUE_APP_DEVBASEURL + '/UpdateUser';

        axios.post(url, form).then((resp) => {

            var data = resp.data;
            if (data.status) {
                commit('User', data.items.user)

                //feth Success Error
            }


        })

    },
    LogOut({ commit }) {


        commit('User', {})
        commit('Token', null)


    }
}

const mutations = {
    User: (state, User) => (state.User = User),
    Token: (state, Token) => (state.Token = Token),
}

export default {
    state,
    getters,
    actions,
    mutations
}