import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import store from "../store/index";
import _ from "lodash";

Vue.use(VueRouter);

function lazyLoad(view) {
    return () =>
        import (`@/views/${view}.vue`);
}

const routes = [{
        path: "/:login?",
        name: "Home",
        component: lazyLoad("home"),
        meta: {
            showChat: true,
        },
        afterEnter: () => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    {
        path: "/Login",
        name: "Login",
        component: lazyLoad("Login"),
        meta: {
            requireGuest: true,
        },
    },
    {
        path: "/Register",
        name: "Register",
        component: lazyLoad("Register"),
        meta: {
            requireGuest: true,
        },
    },
    {
        path: "/User/:param",
        name: "User",
        component: lazyLoad("User"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/LogOut",
        name: "LogOut",
        beforeEnter: () => {
            // Remove Cookies
            VueCookies.remove("stateCount");
            VueCookies.remove("token");

            //Remove User State
            store.dispatch("LogOut");

            //redirect to home if route ! home
            if (router.currentRoute.name != "Home") {
                router.push({ name: "Home" });
            }
        },
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/Search/:term",
        name: "Search",
        component: lazyLoad("search"),
    },
    {
        path: "/Rate/:param",
        name: "Rate",
        component: lazyLoad("rate"),
    },
    {
        path: "/Contact/:param",
        name: "Contact",
        component: lazyLoad("contact"),
    },
    {
        path: "/SpecialOrders/:param",
        name: "SpecialOrders",
        component: lazyLoad("SpecialOrders"),
    },
    {
        path: "/AllRate/:param",
        name: "AllRate",
        component: lazyLoad("AllRate"),
    },
    {
        path: "/Products/:param",
        name: "AllProd",
        component: lazyLoad("AllProd"),
    },
    {
        path: "/Popular/:param",
        name: "Popular",
        component: lazyLoad("popular"),
    },
    {
        path: "/Newest/:param",
        name: "newest",
        component: lazyLoad("newest"),
    },
    {
        path: "/Offers/:param",
        name: "Offers",
        component: lazyLoad("offers"),
    },
    {
        path: "/about/:param",
        name: "About",
        component: lazyLoad("about"),
    },
    {
        path: "/terms/:param",
        name: "terms",
        component: lazyLoad("terms"),
    },
    {
        path: "/privacy/:param",
        name: "privacy",
        component: lazyLoad("privacy"),
    },
    {
        path: "/Categories/:param",
        name: "AllCat",
        component: lazyLoad("AllCat"),
        props: true,
    },
    {
        path: "/Favorite/:param",
        name: "Favorite",
        component: lazyLoad("Favorite"),
    },
    {
        path: "/category/:ProdByCat",
        name: "ProdByCat",
        component: lazyLoad("ProdByCat"),
    },
    {
        path: "/tag/:ProdByTag",
        name: "ProdByTag",
        component: lazyLoad("ProdByTag"),
    },
    {
        path: "/product/:Id",
        name: "ProdOne",
        component: lazyLoad("ProdOne"),
    },
    {
        path: "/RibbanLogo/:param",
        name: "RibbanLogo",
        component: lazyLoad("RibbanLogo"),
    },
    {
        path: "/Shipment",
        name: "Shipment",
        component: lazyLoad("Shipment"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/Billing",
        name: "Billing",
        component: lazyLoad("Billing"),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: "/404",
        name: "404",
        component: lazyLoad("404"),
    },
    {
        path: "/test/:param",
        name: "test",
        component: lazyLoad("test"),
    },
    {
        path: "*",
        redirect: () => {
            return { path: "/404" };
        },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

// function getRoutesList(routes, pre) {
//     return routes.reduce((array, route) => {
//         const path = `${pre}${route.path}`;

//         if (route.path !== '*') {
//             array.push(path);
//         }

//         if (route.children) {
//             array.push(...getRoutesList(route.children, `${path}/`));
//         }

//         return array;
//     }, []);
// }

// function getRoutesXML() {
//     const list = getRoutesList(router.options.routes, 'https://zigamiklic.com')
//         .map(route => `<url><loc>${route}</loc></url>`)
//         .join('\r\n');
//     return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }
// console.log(getRoutesXML());

// router.afterEach((to) => {
//     Vue.prototype.$tidioChatApi &&
//         Vue.prototype.$tidioChatApi.display(to.meta.showChat);

// });
//Check Cookie
// router.beforeEach((to,from,next)=>{

//     // if(Token && UserId){

//     //   // store.commit('User',UserId)
//     //   // store.commit('Token',Token)

//     //   // router.push({ name:'Home' });

//     // }
//     // else{
//     //   next();
//     // }

// })

//Guest Guard
router.beforeEach((to, from, next) => {
    //Check User From Cookies
    var UserCookie = VueCookies.get(
        "stateCount"
    );
    var TokenCookie = VueCookies.get(
        "token"
    );

    if (
        to.meta.requireGuest &&
        !_.isEmpty(UserCookie) &&
        !_.isEmpty(TokenCookie)
    ) {
        router.push({ name: "Home" });
    }

    //Check User From State
    var User = store.getters.User;
    var Token = store.getters.Token;
    if (
        to.meta.requireGuest &&
        !_.isEmpty(User) &&
        !_.isEmpty(Token)
    ) {
        router.push({ name: "Home" });
    }

    if (
        to.meta.requireAuth &&
        _.isEmpty(User) &&
        _.isEmpty(Token)
    ) {
        router.push({ name: "Home" });
    }
    var tidio = document.getElementById(
        "tidio-chat"
    );
    if (tidio != null) {
        if (
            to.name == "Login" ||
            to.name == "Register"
        )
            tidio.style.display = "none";
        else tidio.style.display = "block";
    }

    next();
});

//Auth Guard

export default router;