<template>
  
  <div :class="{displayC: displaCart} " >
    <div class="CartOverlay" @click="hideCart()">

    </div>
    <div class="Cart">
        <button @click="hideCart()" >X</button>
        <h3 class="text-center">السلة</h3>
        <div class="overflow-auto cartBody"  >
            <div v-for="(item, index) in Cart" v-bind:key="index" class='CartItem d-flex' >
                <vue-load-image>
                  <!-- <img slot="image"  class="pic-1"  :src="Prod.images[0].src" :alt="Prod.name" width="500" height="500" > -->
                  <img slot="image" :src="item.item.images[0].src" :alt="item.item.name" width="80" height="80" />
                  <img slot="preloader" src="@/assets/loader.png" :alt="item.item.name" width="80" height="80" />
                  <img slot="error" src="@/assets/loader.png" :alt="item.item.name" width="80" height="80" />
                </vue-load-image>
                <div class="flex-grow-1">
                    <div class="CartItemHeading d-flex justify-content-between">
                        <p>{{ item.item.name }}</p>
                        <button @click="RemoveItem(item.item.id)" class="align-self-start" >X</button>
                    </div>
                    <div class="CartItemBody d-flex justify-content-between">

                        <div v-if="item.item.type ==='simple'">
                            <div v-if="item.item.on_sale">
                                <!-- <p>Product is Simple Has Sale</p> -->
                                <p> <del><span>{{parseFloat(item.item.regular_price).toFixed(3) *item.qty }}</span></del> {{ parseFloat(item.item.sale_price).toFixed(3) *item.qty }}</p>
                            </div>
                            <div v-else>
                                <p>{{ parseFloat(item.item.regular_price).toFixed(3) *item.qty }}</p>
                            </div>
                        </div>
                        <div v-if="item.item.type ==='variable'">
                            <div v-if="item.item.on_sale">
                                <p>Product is variable Has Sale</p>
                            </div>
                            <div v-else>
                                <p>{{ parseFloat(item.item.min_regular_price).toFixed(3) *item.qty }} - {{ parseFloat(item.item.max_regular_price).toFixed(3) *item.qty }}</p>
                            </div>
                        </div>
                        <!-- <p>{{ setPrice(item.item.regular_price) }}</p>
                        <p>{{ setPrice(item.item.sale_price) }}</p> -->
                        <!-- <p>{{ item.item.regular_price }}</p> -->
                        <!-- <p>{{ item.item.sale_price  *item.qty }}</p> -->
                        <div class="d-flex align-self-start qtyCounter">
                            <button class="align-self-start" @click="increaseQty(item.item.id)">+</button>
                            <span>{{ item.qty }}</span>
                            <button class="align-self-start" @click="reduceQty(item.item)" >-</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-bottom position-relative d-flex flex-column">
            <p class="text-center">الأجمالي :{{ Cart.length }} منتجات  - {{ FullPrice }}</p>
            <router-link :to="{ name:'Home' }" class="CheckoutBtn mx-2 text-center">
                إتمام الطلب
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>

import {mapActions,mapGetters} from 'vuex';
import VueLoadImage from 'vue-load-image';
export default {

    data(){
        return {
            displaCart:false
        }
    },
    mounted(){
     this.$root.$on('DisplayCart', () => {
       this.displaCart = ! this.displaCart;
     })
    },
    components:{
        VueLoadImage
    },
    computed:{
        ...mapGetters(['Cart','FullPrice'])
    },
    methods:{
        hideCart:function(){
            this.displaCart=false
        },
        RemoveItem:function(id){

         
            this.RemoveFromCart(id)
          
        },
        increaseQty:function(id){
            this.increaseQtyS(id)
        },
        reduceQty:function(item){

            this.reduceQtyS(item)
        },
        OverlayClicked:function(){
            console.log('overlay clicked')
        },
        ...mapActions(['RemoveFromCart','increaseQtyS','reduceQtyS'])
    }

}
</script>

<style scoped>


.Cart{
    background: #ffffff;
    z-index: 997;
    position: fixed;
    left: -36%;
    width: 36%;
    height: 100%;
}

.Cart h3 {
    margin-top: 8px;
}

.CartOverlay{
    width: 100%;
    height: 100%;
    background: #00000034;
    position: fixed;
    z-index: 997;
    display: none;
}

.displayC .CartOverlay{
    display:block !important;
    backdrop-filter: blur(0.8px);
}

.displayC .Cart{
left:0 !important

}

.CartItem{
border-bottom: 1px rgba(0, 0, 0, 0.096) solid;
padding: 6px;
margin: 0 6px;

}
.CartItemHeading , .CartItemBody{
    text-align: right;
    padding: 6px;
}

.CartItemHeading p {
    font-weight: bold;
}

.CartItemBody p {
    font-size: 12px;
    font-weight: bold;
    margin: auto 6px;
    color: #fa650f;
}

.qtyCounter{
    border: 1px rgba(0, 0, 0, 0.336) solid;
    border-radius: 6px;
}
.qtyCounter button {
    background: none;
    border: none;
    padding: 6px;
}

.qtyCounter span{
    padding: 6px;
    margin: auto;
}

.CheckoutBtn{
    background: #fa650f;
    border: 1px #fa650f solid;
    border-radius: 6px;
    padding: 6px;
    color: white;
}

.cartBody{
    height: 70%;
}

</style>