import axios from "axios";
import cookie from "vue-cookies";
import Vue from "vue";
import store from "../../store";
const state = {
    LandingLayout: [],
    ProdByCatLayout: [],
    ProdByTagLayout: [],
    ProdOne: {},
    ProdOneSimilarLayout: [],
    OffersLayout: [],
    PopularLayout: [],
    FavoriteProducts: [],
    NewestProducts: [],
};

const getters = {
    LandingLayout: (state) => state.LandingLayout,
    ProdByCat: (state) => state.ProdByCat,
    ProdByCatLayout: (state) => state.ProdByCatLayout,
    FavoriteProducts: (state) => state.FavoriteProducts,
    NewestProducts: (state) => state.NewestProducts,
    ProdByTagLayout: (state) => state.ProdByTagLayout,
    ProdOne: (state) => state.ProdOne,
    ProdOneSimilarLayout: (state) => state.ProdOneSimilarLayout,
    OffersLayout: (state) => state.OffersLayout,
    PopularLayout: (state) => state.PopularLayout,
};

const actions = {
    getLanding({ commit }, obj) {
        //Progress Bar
        Vue.prototype.$Progress.start();

        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get(
            "wmc_current_currency"
        );
        let cur;
        let country;

        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }
        var setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/Products/" +
            cur +
            "/" +
            country +
            "/" +
            obj.breakpoint;

        axios.get(setUrl).then(function(response) {
            // landing page
            var items = response.data;
            commit("LandingLayout", items);
            //Finish Progress
            Vue.prototype.$Progress.finish();
            window.scrollTo(0, 0);
        });
    },

    getProdByCat({ commit }, obj) {
        //Start Progress
        Vue.prototype.$Progress.start();
        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get(
            "wmc_current_currency"
        );
        let cur;
        let country;
        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }
        var setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/ProdByCat/" +
            obj.Cat +
            "/" +
            cur +
            "/" +
            country +
            "/" +
            obj.breakpoint +
            "/" +
            obj.filter.condition +
            "/" +
            obj.filter.maxprice +
            "/" +
            obj.filter.minprice +
            "/" +
            obj.filter.highRate +
            "/" +
            obj.filter.lowRate;
        axios.get(setUrl).then(function(response) {
            // Product By Category
            var items = response.data;

            commit("ProdByCatLayout", items);

            //Finish Progress
            Vue.prototype.$Progress.finish();
            window.scrollTo(0, 0);
        });
    },
    getProdByTag({ commit }, obj) {
        //Start Progress
        Vue.prototype.$Progress.start();

        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get(
            "wmc_current_currency"
        );
        let cur;
        let country;
        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }

        var setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/ProdByTag/" +
            obj.tag +
            "/" +
            cur +
            "/" +
            country +
            "/" +
            obj.breakpoint +
            "/" +
            obj.filter.condition +
            "/" +
            obj.filter.maxprice +
            "/" +
            obj.filter.minprice +
            "/" +
            obj.filter.highRate +
            "/" +
            obj.filter.lowRate;
        axios.get(setUrl).then(function(response) {
            //anding Page Layout
            // Product By Category
            var items = response.data;
            if (obj.Where === "ProdByTag") {
                commit("ProdByTagLayout", items);
            } else if (obj.Where === "popular") {
                commit("PopularLayout", items);
            } else if (obj.Where === "offers") {
                commit("OffersLayout", items);
            }

            //Finish Progress
            Vue.prototype.$Progress.finish();
            window.scrollTo(0, 0);
        });
    },
    getProdOne({ commit }, obj) {
        //Start Progress
        Vue.prototype.$Progress.start();

        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get("wmc_current_currency");
        let cur;
        let country;
        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }
        var url =
            process.env.VUE_APP_DEVBASEURL +
            "/ProdOne/" +
            obj.id +
            "/" +
            cur +
            "/" +
            country;
        axios.get(url).then(function(response) {
            commit("ProdOne", response.data.item);
            var myProdID = response.data.item.id;
            var similarArray = response.data.similar.items.filter((prod) => {
                return prod.id != myProdID;
            });
            commit("ProdOneSimilarLayout", similarArray);

            //Finish Progress
            Vue.prototype.$Progress.finish();
            // window.scrollTo(0, 0)
        });
    },
    getFavorite({ commit }, obj) {
        //Start Progress
        Vue.prototype.$Progress.start();
        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get(
            "wmc_current_currency"
        );
        let cur;
        let country;
        let user = store.state.User;
        let id = user.User.ID;
        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }
        var setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/Favorite/" +
            id +
            "/" +
            cur +
            "/" +
            country +
            "/" +
            obj.breakpoint +
            "?page=" +
            obj.page;
        if (id)
            axios.get(setUrl).then(function(response) {
                // Product By Category
                var items = response.data;

                commit("FavoriteProducts", items);

                //Finish Progress
                Vue.prototype.$Progress.finish();
                // window.scrollTo(0, 0)
            });
    },
    getNewest({ commit }, obj) {
        //Start Progress
        Vue.prototype.$Progress.start();
        var CountryVal = cookie.get("shipCountry");
        var CurrVal = cookie.get("wmc_current_currency");
        let cur;
        let country;
        if (CountryVal && CurrVal) {
            cur = CurrVal;
            country = CountryVal;
        } else {
            cur = "SAR";
            country = "SA";
        }
        var setUrl =
            process.env.VUE_APP_DEVBASEURL +
            "/Newest/" +
            cur +
            "/" +
            country +
            "/" +
            obj.breakpoint +
            "?page=" +
            obj.page;
        axios.get(setUrl).then(function(response) {
            // Product By Category
            var items = response.data;

            commit("NewestProducts", items);

            //Finish Progress
            Vue.prototype.$Progress.finish();
            // window.scrollTo(0, 0)
        });
    },
};

const mutations = {
    LandingLayout: (state, LandingLayout) =>
        (state.LandingLayout = LandingLayout),
    ProdByCatLayout: (state, ProdByCatLayout) =>
        (state.ProdByCatLayout = ProdByCatLayout),
    FavoriteProducts: (state, FavoriteProducts) =>
        (state.FavoriteProducts = FavoriteProducts),
    NewestProducts: (state, NewestProducts) =>
        (state.NewestProducts = NewestProducts),
    ProdByTagLayout: (state, ProdByTagLayout) =>
        (state.ProdByTagLayout = ProdByTagLayout),
    PopularLayout: (state, PopularLayout) =>
        (state.PopularLayout = PopularLayout),
    OffersLayout: (state, OffersLayout) => (state.OffersLayout = OffersLayout),
    ProdOne: (state, ProdOne) => (state.ProdOne = ProdOne),
    ProdOneSimilarLayout: (state, ProdOneSimilarLayout) =>
        (state.ProdOneSimilarLayout = ProdOneSimilarLayout),
};

export default {
    state,
    getters,
    actions,
    mutations,
};